<template>
  <label class="toggle">
    <input v-model="value" type="checkbox" />

    <span class="mr-4 text-sm font-semibold input__label">{{ label }}</span>

    <div class="input__clone" />
  </label>
</template>

<script>
export default {
  name: "InputToggle",

  props: {
    checked: Boolean,
    label: {
      type: String,
      default: "",
    },
    index: { type: Number, default: null },
  },

  data() {
    return {
      value: false,
    };
  },

  watch: {
    value() {
      this.$emit("toggle", this.value, this.index);
    },
  },

  created() {
    this.value = this.checked ? this.checked : false;
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors";
@import "../../styles/globals";

.toggle {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    .input__label {
      color: color(blue, lighter);
    }
  }
}

.input__label {
  font-size: 0.875rem;
  margin-right: 1rem;
  transition: var(--transition-in);
  color: color(blue, light);
  white-space: nowrap;
}

.toggle .input__clone {
  width: 3.5rem;
  height: 2rem;
  border-radius: 1rem;
  border: 1px solid color(border, base);
  background-color: color(white, off);

  &::after {
    display: block;
    content: "";
    width: 1.25rem;
    height: 1.25rem;
    background-color: #ccc;
    border-radius: 50%;
    transition: var(--transition-in);
    position: absolute;
    top: 5px;
    left: 6px;
  }
}

.toggle input {
  visibility: hidden;
  width: 0;
  opacity: 0;

  &:checked {
    ~ .input__label {
      color: color(blue, lighter);
    }

    ~ .input__clone {
      background-color: #ebebff;

      &::after {
        background-color: color(cta, primary);
        left: calc(100% - 5px);
        transform: translate(-100%);
      }
    }
  }
}
</style>
